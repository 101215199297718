import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="cart-32_svg__a" d="M24.993 11.2a.634.634 0 0 0-.645-.55h-3.545V8.626c0-2.545-2.322-4.626-5.16-4.626-2.837 0-5.164 2.082-5.164 4.626v2.024H6.932a.634.634 0 0 0-.645.55L5 27.392c0 .16.057.313.161.434.13.122.306.184.484.173h19.993a.698.698 0 0 0 .484-.173.472.472 0 0 0 .161-.434zM11.772 8.626c0-1.908 1.74-3.47 3.869-3.47 2.128 0 3.87 1.562 3.87 3.47v2.024h-7.74zm-5.45 18.217 1.193-15.036h2.964v3.759a.649.649 0 0 0 1.29 0v-3.76h7.742v3.76a.649.649 0 0 0 1.29 0v-3.76h2.934l1.193 15.037z" /></defs><g fill="none" fillRule="evenodd"><mask id="cart-32_svg__b" fill="#fff"><use xlinkHref="#cart-32_svg__a" /></mask><g fill="currentColor" mask="url(#cart-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;